.hover\:bg-gray:hover {
  background-color: #bbb !important;
}
.hover\:bg-gray {
  transition-property: background-color !important;
  transition-duration: .3s !important;
}
.hover\:underline:hover {
  text-decoration: underline !important;
}
.hover\:contrast-150:hover {
  filter: contrast(150%) !important;
}


/* START TOGGLE SWITCH CLASSES */
/* Genel stil */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Giriş stil */
.toggle-switch .toggle-input {
  display: none;
}

/* Anahtarın stilinin etrafındaki etiketin stil */
.toggle-switch .toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
  background-color: red;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Anahtarın yuvarlak kısmının stil */
.toggle-switch .toggle-label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

/* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
.toggle-switch .toggle-input:checked + .toggle-label {
  background-color: #4CAF50;
}

.toggle-switch .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}
/* END TOGGLE SWITCH CLASSES */

.loader {
  width: 35px;
  height: 35px;
  border: 5px solid var(--loader-color, #FFF);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-sm {
  width: 20px;
  height: 20px;
  border: 3px solid var(--loader-color, #FFF);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-xs {
  width: 13px;
  height: 13px;
  border: 2px solid var(--loader-color, #FFF);
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader-black{
  --loader-color: black;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 